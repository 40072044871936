import React, { useState, useEffect } from "react"

import { navigate } from "gatsby"

import Loader from "../components/common/Loader"
import Order from "../components/order/order"
import SEO from "../components/seo"
import { fetchOrders } from "../graphql"

const OrdersPage = () => {
  const [orders, setOrders] = useState([])
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    if (localStorage.token) {
      fetchOrders(10, "")
        .then((res) => {
          setIsLoading(false)
          setOrders([...res.me.orders.edges])
        })
        .catch((err) => {
          setIsLoading(false)
          console.log(err)
        })
    } else {
      navigate("/login")
    }
  }, [])

  return (
    <>
      <SEO title="Orders" path="/orders/" />
      {isLoading ? (
        <Loader />
      ) : orders.length === 0 ? (
        <section
          style={{
            fontSize: 20,
            minHeight: "100vh",
            paddingTop: 50,
            textAlign: "center",
            width: "100%",
          }}
        >
          No Previous Orders
        </section>
      ) : (
        <div className="orders">
          <h1 className="orders__heading">Orders</h1>
          {orders.map((order, i) => (
            <Order order={order} key={i} />
          ))}
        </div>
      )}
    </>
  )
}

export default OrdersPage
