import React, { useState } from "react"

import { ChevronDown, ChevronUp } from "akar-icons"
import PropType from "prop-types"

import "./order.scss"
import Link from "../common/Link"

const Order = ({ order }) => {
  const [isExpanded, setIsExpanded] = useState(false)
  const status = order.node?.statusDisplay

  return (
    <div className={`order ${isExpanded && "order--expanded"}`}>
      <div
        className="order__main"
        role="button"
        tabIndex="-1"
        onClick={() => setIsExpanded(!isExpanded)}
        onKeyDown={(e) => e.key === "Enter" && setIsExpanded(!isExpanded)}
      >
        <div className="order__main__left">
          <h4>Order Number: {order.node.number}</h4>
          <p>Ordered on {order.node.created.slice(0, 10)}</p>
          {order.node.customerNote && (
            <p>Customer Note : {order.node.customerNote}</p>
          )}
          <p className="order__main__left__price">
            Total Price:{" "}
            <strong>{`₹ ${order.node.payments[0].total.amount}`}</strong>
          </p>
        </div>
        <div className="order__main__right">
          <h4>{`₹ ${order.node.payments[0].total.amount}`}</h4>
        </div>
        <button
          className="arrow-container"
          onClick={() => setIsExpanded(!isExpanded)}
        >
          {isExpanded ? <ChevronUp /> : <ChevronDown />}
        </button>
        <p className="order__status">Status: {status}</p>
        <div
          className={`order__payment-status ${
            order.node.isPaid && "order__payment-status--paid"
          }`}
        >
          <span className="circle"></span>
          <span>{order.node.isPaid ? "Paid" : "Unpaid"}</span>
        </div>
      </div>
      {isExpanded && (
        <div className="order__products">
          {order.node.lines.map((line, i) => {
            const pathname = `/product/${line.variant.product.slug}`

            return (
              <div className="order__product" key={i}>
                <Link to={pathname}>
                  <div className="order__product__thumbnail">
                    <img src={line.thumbnail.url} alt={line.thumbnail.alt} />
                  </div>
                </Link>
                <div className="order__product__details">
                  <div className="order__product__details__left">
                    <Link to={pathname}>
                      <h3 className="order__product__details__name">
                        {line.productName}
                      </h3>
                    </Link>
                    <p className="order__product__details__price">{`Toatl Price: ₹ ${line.totalPrice.net.amount}`}</p>
                    <p>Category: {line.variant.product.category.name}</p>
                    <p>Quantity: {line.quantity}</p>
                    {order.node?.fulfillments.length > 0 &&
                      order.node.fulfillments[0].trackingNumber !== "" && (
                        <p className="order__product__details__tracking-id">
                          Tracking ID:{" "}
                          {order.node?.fulfillments[0]?.trackingNumber}
                        </p>
                      )}
                  </div>
                  <div className="order__product__details__right">
                    <h4>
                      Total Price:{" "}
                      <strong>{`₹ ${line.totalPrice.net.amount}`}</strong>
                    </h4>
                  </div>
                </div>
              </div>
            )
          })}
        </div>
      )}
    </div>
  )
}

Order.propTypes = {
  order: PropType.object.isRequired,
}

export default Order
